export const useBlogStore = defineStore('blog', () => {
  const { $api } = useNuxtApp();

  const blogsList = ref([]);
  const currentBlog = ref({});
  const blogCategories = ref([]);
  const page = ref(1);
  const isLoadButtonVisible = ref(true);

  const getAllBlogs = async ({ page }) => {
    try {
      const { data } = await $api.web().getPublishedBlogPosts({ page });
      blogsList.value = data.data.rows;
      assignCategoriesToBlogs();
    } catch (error) {
      console.log(error);
    }
  };

  const getMoreBlogs = async ({ page }) => {
    try {
      const { data } = await $api.web().getPublishedBlogPosts({ page });
      blogsList.value = [...blogsList.value, ...data.data.rows];
      assignCategoriesToBlogs();
    } catch (error) {
      isLoadButtonVisible.value = false;
      console.log(error);
    }
  };

  const getOneBlog = async ({ name, category }) => {
    try {
      const { data } = await $api.web().getOnePublishedBlogPost({ name, category });
      currentBlog.value = data.data;
    } catch (error) {
      console.log(error);
    }
  };

  const getBlogCategories = async () => {
    try {
      const { data } = await $api.web().getBlogCategories();
      blogCategories.value = data.data;
    } catch (error) {
      console.log(error);
    }
  };

  const assignCategoriesToBlogs = () => {
    blogsList.value = blogsList.value.map((blog) => {
      const category = blogCategories.value.find((item) => item.id === blog.categoryId);

      return {
        ...blog,
        categoryName: category ? category.name : 'Без категории',
      };
    });
  };

  return {
    getAllBlogs,
    getMoreBlogs,
    getOneBlog,
    getBlogCategories,
    page,
    blogsList,
    currentBlog,
    blogCategories,
    isLoadButtonVisible,
  };
});
